import React from "react"
import PropTypes from "prop-types"
import * as styles from "./__styles__/downloads.module.scss"

import Download from "./../molecules/download"

function Downloads({ downloads, columns }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {downloads.map((download, index) => {

          // This is to resolve a file size limit issue of 20mb on the downloads. Changes the url if filesize over 20mb
          const url = download.file.localFile.size > 20000000 ? download.file.localFile.url : download.file.localFile.publicURL

          return (
            <div
              className={styles.download}
              style={{ width: `calc((100% / ${columns}) - var(--gap))` }}
              key={index}
            >
              <Download
                name={download.name}
                extension={download.file.localFile.extension}
                url={url}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Downloads

Downloads.propTypes = {
  /**
   * A list of downloads (array of objects)
   */
  downloads: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      file: PropTypes.shape({
        localFile: PropTypes.shape({
          extension: PropTypes.string.isRequired,
          publicURL: PropTypes.string.isRequired,
        }),
      }),
    })
  ),
  /**
   * Number of columns (downloads per row)
   */
  columns: PropTypes.oneOf([2, 3, 4]),
}

Downloads.defaultProps = {
  columns: 3,
}
