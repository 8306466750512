import React from "react"
import PropTypes from "prop-types"
import * as styles from "./__styles__/filter.module.scss"

export default function TrustFilter({ term, setFilter }) {
  const handleChange = event => setFilter(event.target.value)
  const handleClear = () => setFilter("")

  return (
    <div className={styles.container}>
      <label htmlFor="filter" className={styles.label}>
        Search for an organisation
        <span>You can search by name, code or benchmarking group</span>
      </label>
      <div className={styles.inputContainer}>
        <input
          className={styles.input}
          type="text"
          id="filter"
          name="filter"
          data-cy="filter"
          value={term}
          placeholder="Search..."
          onChange={handleChange}
        />
        {term !== "" && (
          <button className={styles.reset} type="reset" onClick={handleClear}>
            Reset Search Field
          </button>
        )}
      </div>
    </div>
  )
}

TrustFilter.propTypes = {
  /**
   * String used to filter the list out
   */
  term: PropTypes.string,
}

TrustFilter.defaultProps = {
  term: "",
}
