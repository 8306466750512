// extracted by mini-css-extract-plugin
export const download = "download-module--download--2nZFd";
export const container = "download-module--container--3cJbx";
export const link = "download-module--link--2K9_x";
export const name = "download-module--name--vDqMQ";
export const extension = "download-module--extension--yRSW2";
export const pdf = "download-module--pdf--u2lcN";
export const xls = "download-module--xls--ZwzEI";
export const xlsx = "download-module--xlsx--3JwzW";
export const doc = "download-module--doc--3cBUy";
export const docx = "download-module--docx--3uESv";
export const ppt = "download-module--ppt--1zMLr";
export const pptx = "download-module--pptx--1pM0i";