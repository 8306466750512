import { useStaticQuery, graphql } from "gatsby"

export const useLocalResults = () => {
  const { wp } = useStaticQuery(graphql`
    query {
      wp {
        results {
          year
          trusts {
            name
            code
            sector
            bank {
              url
            }
            benchmark {
              url
            }
            breakdown {
              url
            }
            directorate {
              url
            }
            summary {
              url
            }
          }
        }
      }
    }
  `)

  return wp.results
}
