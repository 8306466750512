import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { useLocalResults } from "../../hooks/use-local-results"
import * as styles from "./__styles__/local-results.module.scss"
import { connect } from "react-redux"
import { updatePageURI } from "./../../redux/actions"

import Layout from "../layouts/base"
import SEO from "../atoms/seo"
import Hero from "../molecules/hero"
import Intro from "../molecules/intro"
import Downloads from "../organisms/downloads"
import TrustResults from "../organisms/trust-results"

// Redux
const mapDispatchToProps = {
  updatePageURIAction: updatePageURI,
}

function Page({ updatePageURIAction, data }) {
  const page = data.wpPage
  const intro = page.intro && page.intro.copy
  const downloads = page.downloads.downloadsItems
  const results = useLocalResults()
  const trusts = results ? results.trusts : null

  useEffect(() => {
    updatePageURIAction(page.uri)
  })

  return (
    <Layout>
      <SEO
        description={page.seo.metaDesc}
        robots={page.seo.metaRobotsNoindex}
        slug={page.uri}
        title={page.seo.title}
      />
      <Hero
        title={page.hero.title || page.title}
        lead={page.hero.lead}
        pageId={page.id}
      />
      {intro && <Intro copy={intro} />}
      {downloads && (
        <div className={styles.downloadsContainer}>
          <Downloads downloads={downloads} columns={3} />
        </div>
      )}
      <TrustResults trusts={trusts} />
    </Layout>
  )
}

export default connect(null, mapDispatchToProps)(Page)

export const query = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      title
      uri
      seo {
        title
        metaDesc
        metaRobotsNoindex
      }
      hero {
        title
        lead
      }
      intro {
        copy
      }
      downloads {
        downloadsItems {
          name
          file {
            localFile {
              extension
              publicURL
              url
              size
            }
          }
        }
      }
    }
  }
`
