import React from "react"
import PropTypes from "prop-types"
import * as styles from "./__styles__/intro.module.scss"

import Copy from "../atoms/copy"

export default function Intro({ copy }) {
  return (
    <section className={styles.container}>
      <Copy copy={copy} />
    </section>
  )
}

Intro.propTypes = {
  /**
   * HTML copy
   */
  copy: PropTypes.string.isRequired,
}
