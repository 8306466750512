import React, { useState } from "react"
import PropTypes from "prop-types"
import * as styles from "./__styles__/trust-results.module.scss"

import Filter from "../molecules/filter"
import Card from "../molecules/trust-card"

import NotFoundSrc from "./../../images/svgs/not-found.svg"

function TrustResults({ trusts, term }) {
  const [filter, setFilter] = useState(term)
  const filteredTrusts =
    trusts &&
    trusts.filter(
      trust =>
        trust.name.toLowerCase().includes(filter.toLowerCase()) ||
        trust.code.toLowerCase().includes(filter.toLowerCase()) ||
        trust.sector.toLowerCase().includes(filter.toLowerCase()) ||
        filter === ""
    )
  const matches = trusts && filteredTrusts.length

  return (
    <div className={styles.container}>
      <Filter term={filter} setFilter={setFilter} />

      {/* Matches */}
      <div className={styles.matches}>
        {filter !== "" && trusts && (
          <div>
            Number of organisations found:{" "}
            <span data-cy="num-matches">{matches}</span>
          </div>
        )}
        {!trusts && (
          <div>
            Sorry, there are no results to show at the moment, please try again
            later.
          </div>
        )}
      </div>

      {/* Trust cards */}
      {trusts && matches > 0 && (
        <div className={styles.trusts} data-cy="trusts">
          {filteredTrusts.map((trust, index) => (
            <div key={index}>
              <Card
                id={index}
                name={trust.name}
                code={trust.code}
                sector={trust.sector}
                bankUrl={trust.bank ? trust.bank.url : null}
                benchmarkUrl={trust.benchmark ? trust.benchmark.url : null}
                breakdownUrl={trust.breakdown ? trust.breakdown.url : null}
                directorateUrl={
                  trust.directorate ? trust.directorate.url : null
                }
                summaryUrl={trust.summary ? trust.summary.url : null}
              />
            </div>
          ))}
        </div>
      )}

      {/* No results found or no trusts injected */}
      {(matches === 0 || !trusts) && (
        <div className={styles.noResults} data-cy="no-results">
          <NotFoundSrc />
        </div>
      )}
    </div>
  )
}

export default TrustResults

TrustResults.propTypes = {
  /**
   * An array of Trust objects
   */
  trusts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    })
  ),
  /**
   * The term used to filter
   */
  term: PropTypes.string,
}

TrustResults.defaultProps = {
  term: "",
}
