import React from "react"
import PropTypes from "prop-types"
import * as styles from "./__styles__/trust-card.module.scss"

export default function TrustCard({
  name,
  code,
  sector,
  bankUrl,
  benchmarkUrl,
  breakdownUrl,
  directorateUrl,
  summaryUrl,
  id,
}) {
  return (
    <div className={styles.card} data-cy="card">
      <header className={styles.header}>
        <h2 id={`trust-card-title${id}`} className={styles.name}>
          {name}
        </h2>
        <div id={`trust-card-code${id}`} className={styles.code}>
          {code}
        </div>
      </header>
      <div className={styles.footer}>
        <span className={styles.sector}>{sector}</span>
        <div className={styles.downloads}>
          <span className={styles.downloadsTitle}>Downloads</span>
          <ul>
            {benchmarkUrl && (
              <li>
                <a
                  aria-labelledby={`trust-card-code${id} trust-card-title${id}`}
                  href={benchmarkUrl}
                  target="blank"
                  rel="noopener"
                >
                  Benchmark report
                </a>
              </li>
            )}
            {breakdownUrl && (
              <li>
                <a
                  aria-labelledby={`trust-card-code${id} trust-card-title${id}`}
                  href={breakdownUrl}
                  target="blank"
                  rel="noopener"
                >
                  Breakdown report
                </a>
              </li>
            )}
            {directorateUrl && (
              <li>
                <a
                  aria-labelledby={`trust-card-code${id} trust-card-title${id}`}
                  href={directorateUrl}
                  target="blank"
                  rel="noopener"
                >
                  Directorate report
                </a>
              </li>
            )}
            {summaryUrl && (
              <li>
                <a
                  aria-labelledby={`trust-card-code${id} trust-card-title${id}`}
                  href={summaryUrl}
                  target="blank"
                  rel="noopener"
                >
                  Summary report
                </a>
              </li>
            )}
            {bankUrl && (
              <li>
                <a
                  aria-labelledby={`trust-card-code${id} trust-card-title${id}`}
                  href={bankUrl}
                  target="blank"
                  rel="noopener"
                >
                  Bank report
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

TrustCard.propTypes = {
  /**
   * The name of the Trust
   */
  name: PropTypes.string.isRequired,
  /**
   * The code of the Trust
   */
  code: PropTypes.string.isRequired,
  /**
   * The sector of the Trust
   */
  sector: PropTypes.string,
  /**
   * The bank's file URL
   */
  bankUrl: PropTypes.string,
  /**
   * The benchmark's file URL
   */
  benchmarkUrl: PropTypes.string,
  /**
   * The breakdown's file URL
   */
  breakdownUrl: PropTypes.string,
  /**
   * The directorate's file URL
   */
  directorateUrl: PropTypes.string,
  /**
   * The summary's file URL
   */
  summaryUrl: PropTypes.string,
  /**
   * id for aria-labeledby
   */
  id: PropTypes.number.isRequired,
}

TrustCard.defaultProps = {
  bankUrl: "",
  benchmarkUrl: "",
  breakdownUrl: "",
  directorateUrl: "",
  summaryUrl: "",
}
