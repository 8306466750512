import React from "react"
import PropTypes from "prop-types"
import * as styles from "./__styles__/download.module.scss"

export default function Download({ name, extension, url }) {
  return (
    <a className={styles.download} href={url} rel="noreferrer" download>
      <div className={styles.container}>
        <div className={styles.name}>{name}</div>
        <div className={[styles.extension, styles[`${extension}`]].join(" ")}>
          <span>{extension.substring(0, 4)}</span>
        </div>
        <div className={styles.link}>Download</div>
      </div>
    </a>
  )
}

Download.propTypes = {
  /**
   * The name show on this card. This does not need to be the filename
   */
  name: PropTypes.string.isRequired,
  /**
   * The file extension. It shouldn't be more than 4 letters
   */
  extension: PropTypes.string.isRequired,
  /**
   * The URL of the file to download
   */
  url: PropTypes.string.isRequired,
}
